import PropTypes from 'prop-types';
import React from 'react';

import styles from './burger.module.css';

const Burger = ({ active, clickHandler, className }) => {

  const activeStyle = active ? styles.active : '';

  return (
    <button className={[styles.burger, activeStyle, className].join(' ')} onClick={clickHandler}>
        <span className={styles.bar1} key="b1"></span>
        <span className={styles.bar2} key="b2"></span>
        <span className={styles.bar3} key="b3"></span>
    </button>
  )
}
Burger.propTypes = {
  active: PropTypes.bool,
  clickHandler: PropTypes.func,
  className: PropTypes.string
}

Burger.defaultProps = {
  active: false,
  className: ''
}

export default Burger;
