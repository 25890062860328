import React, { useState } from 'react';
import ReactCookieConsent from 'react-cookie-consent';

import Modal    from '../modal/Modal';
import Privacy  from '../modalContent/Privacy';

import styles   from './cookieConsent.module.css';

const CookieConsent = () => {
  const [modalPrivacy, setmodalPrivacy] = useState(false);

  const handlePrivacyClick = e => {
    e.stopPropagation();
    e.preventDefault();
    setmodalPrivacy(true);
  }

  return (
    <>
      <ReactCookieConsent
        location="none"
        overlay={true}
        disableStyles={true}
        enableDeclineButton={true}
        expires={150}
        onAccept={() => window.location.reload()}
        flipButtons

        cookieName="gatsby-gdpr-google-tagmanager"
        buttonText="Alle Cookies akzeptieren"
        declineButtonText='Nur notwendige Cookies akzeptieren'

        overlayClasses= {styles.overlay}
        containerClasses={styles.cookieConsent}
        buttonClasses={[styles.button, styles.buttonAccept].join(' ')}
        declineButtonClasses={[styles.button, styles.buttonDecline].join(' ')}
        contentClasses={styles.content}
      >
        <h3>Cookies auf neukunden.heinrich-haeusler.de</h3>
        <p>
          Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht.
        </p>
        <p>
          Wir respektieren Ihr Recht auf den Schutz Ihrer Daten. Deshalb können Sie entscheiden, bestimmte Arten von Cookies nicht zu akzeptieren. Sie können diese Einstellungen jederzeit ändern, wenn Sie unsere <a href="/datenschutz" onClick={handlePrivacyClick}>Datenschutzerklärung</a> aufrufen.
        </p>
        <Modal open={modalPrivacy} handleClose={() => setmodalPrivacy(false)}><Privacy /></Modal>
      </ReactCookieConsent>
    </>
  )
}

export default CookieConsent;
