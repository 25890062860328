import PropTypes from "prop-types"
import React from "react";
import { Link } from 'gatsby';

import styles from "./footer.module.css"

const Footer = ({ className }) => (
  <div className={styles.footer}>
    <footer>
      <nav className={styles.nav}>
        <Link className={styles.navItem} to="/agb">AGB</Link>
        <Link className={styles.navItem} to="/datenschutz">Datenschutz</Link>
        <a className={styles.navItem} href="https://heinrich-haeusler.de/about-us" target="_blank" rel="noreferrer">Impressum</a>
      </nav>
    </footer>
  </div>
)

Footer.propTypes = {
  className: PropTypes.string
}

Footer.defaultProps = {
  className: ''
}

export default Footer;
