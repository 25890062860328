import PropTypes from "prop-types"
import React from "react"
import BackgroundImage from 'gatsby-background-image'

import styles from "./stage.module.css"

const Stage = ({children, anchor, bgImage, bgStyle, fullWidth, fullHeight, className }) => {

  const fullWidthStyle = fullWidth ? styles.fullWidth : null;
  const fullHeightStyle = fullHeight ? styles.fullHeight : null;

  if (bgImage) {
    return (
      <BackgroundImage
        Tag="section"
        className={[styles.imageStage, className].join(' ')}
        fluid={bgImage}
        backgroundColor={`#F3F3F3`}
        id={anchor}
        style={bgStyle}
      >
        <div className={[styles.container, fullWidthStyle, fullHeightStyle].join(' ')}>
          {children}
        </div>
      </BackgroundImage>
    );
  }

  return (
    <section id={anchor} className={[styles.stage, className].join(' ')}>
      <div className={[styles.container, fullWidthStyle, fullHeightStyle].join(' ')}>
        {children}
      </div>
    </section>
  );
}

Stage.propTypes = {
  /** Stage ID for anchor navigation */
  anchor: PropTypes.string,
  /** Stretched backround image */
  bgImage: PropTypes.object,
  /** Styles Object for overriding the default bgImage styles */
  bgStyle: PropTypes.object,
  /** The content container becomes a 100% width */
  fullWidth: PropTypes.bool,
  /** The content container becomes full height of the browser viewport */
  fullHeight: PropTypes.bool,
  /** Additional classname for individual styling in the the parent component */
  className: PropTypes.string
}

Stage.defaultProps = {
  anchor: '',
  bgImage: null,
  bgStyle: null,
  fullWidth: false,
  fullHeight: false,
  className: null
}

export default Stage;
