import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import { animateScroll as scroll } from 'react-scroll';

import Navigation from "./navigation/navigation";

import Logo from '../../../images/logo.svg';
import styles from "./header.module.css";

const Header = ({ children, scrollLogo, logoText, menuItems, className }) => {

  const scrollToTop = (e) => {
    e.preventDefault();

    scroll.scrollToTop({
      duration: 1500,
      delay: 100,
      smooth: 'easeInOutQuart',
    });
  }

  return (
    <div className={[styles.wrapper, className].join(' ')}>
        <header className={styles.header}>
            <div className={styles.logo}>
              {scrollLogo ?
                <a href="/" onClick={scrollToTop} aria-label="Häusler"><Logo title={logoText} /></a>
              :
                <Link to="/"><Logo title={logoText} /></Link>
              }
            </div>
            <Navigation menuItems={menuItems}>
              {children}
            </Navigation>
        </header>
    </div>
  )
}

Header.propTypes = {
  scrollLogo: PropTypes.bool,
  logoText: PropTypes.string,
  menuItems: PropTypes.array,
  className: PropTypes.string
}

Header.defaultProps = {
  scrollLogo: false,
  logoText: '',
  menuItems: [],
  className: ''
}

export default Header;
