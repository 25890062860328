import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from 'gatsby';
import { Link as ScrollTo } from 'react-scroll';

import Burger from '../burger/burger';

import styles from './navigation.module.css';

const Navigation = ({ children, menuItems, className }) => {

  const [burgerActive, setBurgerActive] = useState(false);

  const toggleBurgerClickHandler = () => {
    setBurgerActive(!burgerActive);
  }

  const Overlay = () => <div className={styles.overlay} onClick={toggleBurgerClickHandler} aria-hidden="true"></div>

  return (
    <>
      {burgerActive && <Overlay />}
      <div className={[styles.nav, burgerActive && styles.active, className].join(' ')}>
          {children}
          {menuItems.length > 0 && (
            <nav className={styles.items}>
            {menuItems.map((item, index) => (
              item.anchor ?
                <ScrollTo key={index} to={item.anchor} spy={true} smooth={'easeInOutQuart'} duration={1500} offset={-90} onClick={toggleBurgerClickHandler}>
                  {item.title}
                </ScrollTo>
              :
                <Link key={index} to={item.href} target={item.target ? '_self' : item.target} onClick={toggleBurgerClickHandler}>
                  {item.title}
                </Link>
            ))}
            </nav>
          )}
      </div>
      <Burger active={burgerActive} clickHandler={toggleBurgerClickHandler} />
    </>
  )
}

Navigation.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
      target: PropTypes.string,
      active: PropTypes.bool
    })
  ),
  className: PropTypes.string
}

Navigation.defaultProps = {
  menuItems: [],
  className: ''
}

export default Navigation;
