import React from 'react';

import Button from '../../atoms/button/button';
import styles from './modal.module.css';

const Modal = ({children, open, handleClose}) => {

  const _handleClose = () => {
    handleClose();
  }

  return  open ?
    <div className={styles.background}>
      <div className={styles.modal}>
        <Button tagName="button" htmlType="button" styleType="text" className={styles.close} onClick={_handleClose}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z" fill="#E2061B"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="#E2061B"/>
          </svg>
        </Button>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  : null;
}

export default Modal;
